@charset "UTF-8";
/*===========================
reset style
===========================*/
/* autoprefixer grid: autoplace */
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html, body {
  min-height: 100%;
}

body {
  font-family: YakuHanJP, "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "游ゴシック体", "Yu Gothic", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  text-align: center;
  color: #2C2C2C;
  background: #ffffff;
  font-size: 16px;
  letter-spacing: 1.2px;
  font-feature-settings: "palt";
}
body.is-en {
  font-family: "Roboto Condensed", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  color: #2C2C2C;
}

p {
  font-size: 16px;
  line-height: 2.2em;
  color: #2C2C2C;
}

ul, ol, li {
  list-style: none;
  color: #2C2C2C;
}

a {
  text-decoration: none;
  color: #2C2C2C;
}
a:visited {
  text-decoration: none;
  color: #2C2C2C;
}
a:hover {
  text-decoration: none;
}

address {
  font-style: normal;
  color: #2C2C2C;
}

img {
  border: none;
  vertical-align: bottom;
}

dl, dt, dd {
  margin: 0;
  padding: 0;
  color: #2C2C2C;
}

main {
  display: block;
}

picture {
  display: block;
}

table, th, td {
  color: #2C2C2C;
}

header {
  display: block;
}

footer {
  display: block;
}

aside {
  display: block;
}

pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

small {
  display: block;
}

data {
  display: block;
}

time {
  display: block;
}

a[href^="tel:"] {
  pointer-events: none;
  cursor: default;
}
@media screen and (max-width: 767px) {
  a[href^="tel:"] {
    pointer-events: auto;
    cursor: pointer;
  }
}

/*==========================
l-header
==========================*/
.l-header {
  width: 100%;
  height: 130px;
  background-color: #EE8A18;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transform: translateY(0);
  transition: 0.3s;
}
@media screen and (max-width: 767px) {
  .l-header {
    height: 65px;
  }
}
.l-header.js-hide {
  transform: translateY(-100%);
  transition: 0.3s;
}
.l-header.is-active {
  background-color: #EE8A18;
}
.l-header__inner {
  height: 100%;
  padding: 0 50px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .l-header__inner {
    padding: 0 20px;
  }
}
@media screen and (max-width: 767px) {
  .l-header__split:nth-of-type(1) {
    width: 31.4%;
    max-width: 210px;
  }
}
.l-header__logo {
  line-height: 0em;
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
  backface-visibility: hidden;
}
.l-header__logo:hover {
  opacity: 0.7;
  transition: 0.3s;
}
.l-header__logo img {
  max-width: 100%;
}
.l-header__link {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .l-header__link {
    padding-right: 40px;
  }
}
@media screen and (max-width: 767px) {
  .l-header__linkContact {
    display: none;
  }
}
.l-header__linkLang {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.l-header__linkLang:after {
  content: "";
  display: block;
  width: 1px;
  height: 12px;
  background-color: #ffffff;
  order: 2;
}
.l-header__linkLangItem {
  padding: 0 10px;
  line-height: 0em;
}
.l-header__linkLangItem:nth-of-type(1) {
  order: 1;
}
.l-header__linkLangItem:nth-of-type(2) {
  order: 3;
}
.l-header__linkLangItem a {
  color: #ffffff !important;
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
  backface-visibility: hidden;
  line-height: 1em;
}
.l-header__linkLangItem a:hover {
  opacity: 0.7;
  transition: 0.3s;
}
@media screen and (max-width: 767px) {
  .l-header__linkLangItem a {
    font-size: 12px;
  }
}
.l-header__gnav {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .l-header__gnav {
    display: none;
  }
}
.l-header__gnav > li {
  margin-left: 30px;
}
.l-header__gnav a {
  font-size: 16px;
  font-weight: bold;
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
  backface-visibility: hidden;
  color: #ffffff !important;
}
.l-header__gnav a:hover {
  opacity: 0.7;
  transition: 0.3s;
}
.l-header__hmb {
  display: none;
}
@media screen and (max-width: 767px) {
  .l-header__hmb {
    width: 20px;
    height: 18px;
    display: block;
    position: fixed;
    top: 18%;
    left: auto;
    right: 20px;
    z-index: 1000001;
  }
  .l-header__hmbButton {
    appearance: none;
    resize: none;
    padding: 0;
    border: 0;
    outline: none;
    background: transparent;
    font-family: YakuHanJP, "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "游ゴシック体", "Yu Gothic", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    width: 100%;
    height: 40px;
    position: relative;
  }
  .l-header__hmbButton > div {
    width: 100%;
    height: 1.5px;
    background: #ffffff;
    position: absolute;
    top: 30%;
    left: 50%;
    transition: 0.3s;
  }
  .l-header__hmbButton > div:nth-of-type(1) {
    transform: translate(-50%, 0) rotate(0deg);
  }
  .l-header__hmbButton > div:nth-of-type(2) {
    top: 50%;
    transform: translate(-50%, 0) rotate(0deg);
  }
  .l-header__hmbButton > div:nth-of-type(3) {
    top: 70%;
    transform: translate(-50%, 0) rotate(0deg);
  }
  .l-header__hmbButton.is-active > div {
    transition: 0.3s;
  }
  .l-header__hmbButton.is-active > div:nth-of-type(1) {
    top: 50%;
    transform: translate(-50%, 0) rotate(45deg);
  }
  .l-header__hmbButton.is-active > div:nth-of-type(2) {
    opacity: 0;
  }
  .l-header__hmbButton.is-active > div:nth-of-type(3) {
    top: 50%;
    transform: translate(-50%, 0) rotate(-45deg);
  }
}
.l-header__drwNav {
  display: none;
}
@media screen and (max-width: 767px) {
  .l-header__drwNav {
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    width: 100%;
    height: calc(100vh - 65px);
    background-color: #EE8A18;
    position: fixed;
    top: 65px;
    left: 0;
    padding: 0 20px;
  }
  .l-header__drwNav.is-active {
    visibility: visible;
    opacity: 1;
    transition: 0.3s;
    z-index: 10000000000000000;
  }
}
@media screen and (max-width: 767px) {
  .l-header__drwNavList {
    height: 62vh;
    margin-top: 30px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
.l-header__drwNavListItem {
  border-bottom: 1px solid #ffffff;
}
.l-header__drwNavListItemTit {
  height: 50px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.l-header__drwNavListItemTit::before, .l-header__drwNavListItemTit::after {
  content: none !important;
}
@media screen and (max-width: 767px) {
  .l-header__drwNavListItemTit::before, .l-header__drwNavListItemTit::after {
    content: "" !important;
    background-color: #ffffff;
  }
}
.l-header__drwNavListItemTit a {
  color: #ffffff !important;
}
@media screen and (max-width: 767px) {
  .l-header__drwNavListItemTit a {
    font-size: 16px;
  }
}
.l-header__drwNavListItemMain {
  padding-left: 20px;
  padding-bottom: 20px;
}
.l-header__drwNavListItemMainLink {
  text-align: left;
}
.l-header__drwNavListItemMainLink a {
  color: #ffffff !important;
  display: block;
  line-height: 1em;
  margin-top: 20px;
  font-size: 14px;
}
.l-header__drwNavListItemMainLink a:first-of-type {
  margin-top: 0;
}
@media screen and (max-width: 767px) {
  .l-header__drwNavReserve {
    margin-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  .l-header__drwNavReserve .m-button {
    width: 37.3vw;
    height: 30px;
    max-width: 250px;
    margin: 0 auto;
    font-size: 11px;
  }
}

/*==========================
l-footer
==========================*/
.l-footer {
  margin-top: 100px;
}
.l-footer__inner {
  width: 100%;
  max-width: 1065px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .l-footer__inner {
    max-width: initial;
  }
}
.l-footer__split:nth-of-type(1) {
  background-color: #595959;
  padding: 80px 0 70px;
}
@media screen and (max-width: 767px) {
  .l-footer__split:nth-of-type(1) {
    padding: 0 0;
  }
}
.l-footer__split:nth-of-type(2) {
  background-color: #484848;
  border-top: 1px solid #EE8A18;
  padding: 40px 0 20px;
}
.l-footer__split:nth-of-type(2) .l-footer__inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
@media screen and (max-width: 767px) {
  .l-footer__split:nth-of-type(2) .l-footer__inner {
    display: block;
    padding: 0 40px 0;
  }
}
.l-footer__link {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
@media screen and (max-width: 767px) {
  .l-footer__link {
    display: block;
  }
}
.l-footer__linkDoboz {
  width: 23%;
  max-width: 244px;
}
@media screen and (max-width: 767px) {
  .l-footer__linkDoboz {
    width: 100%;
    max-width: initial;
  }
}
.l-footer__linkDoboz:first-of-type .l-footer__linkDobozTit {
  border-top: none;
}
.l-footer__linkDoboz-full {
  width: 30%;
  max-width: initial;
}
.l-footer__linkDobozTit {
  font-size: 16px;
  color: #ffffff;
  border-bottom: 1px solid #E0E0E0;
  text-align: left;
  padding-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .l-footer__linkDobozTit {
    padding: 0 20px;
    height: 70px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: none;
    border-top: 1px solid #E0E0E0;
  }
}
.l-footer__linkDobozTit::before, .l-footer__linkDobozTit::after {
  content: none !important;
}
@media screen and (max-width: 767px) {
  .l-footer__linkDobozTit::before, .l-footer__linkDobozTit::after {
    content: "" !important;
    background-color: #ffffff;
  }
}
.l-footer__linkDobozTit a {
  color: #ffffff !important;
}
@media screen and (max-width: 767px) {
  .l-footer__linkDobozList {
    padding: 0 20px 20px;
    border-top: 1px solid #E0E0E0;
  }
}
.l-footer__linkDobozList.js-toggle__next {
  display: block;
}
@media screen and (max-width: 767px) {
  .l-footer__linkDobozList.js-toggle__next {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .l-footer__linkDobozList-has-empty {
    padding: 0 0;
    border-top: none;
  }
}
.l-footer__linkDobozListFlx {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}
.l-footer__linkDobozListItem {
  text-align: left;
  margin-top: 20px;
  line-height: 1em;
}
@media screen and (max-width: 767px) {
  .l-footer__linkDobozListItem {
    width: 50%;
  }
}
.l-footer__linkDobozListItem:nth-of-type(odd) {
  width: 41%;
}
@media screen and (max-width: 767px) {
  .l-footer__linkDobozListItem:nth-of-type(odd) {
    width: 50%;
  }
}
.l-footer__linkDobozListItem-full {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .l-footer__linkDobozListItem-full {
    width: 100%;
  }
}
.l-footer__linkDobozListItem-full:nth-of-type(odd) {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .l-footer__linkDobozListItem-full:nth-of-type(odd) {
    width: 100%;
  }
}
.l-footer__linkDobozListItem a {
  color: #ffffff !important;
  font-size: 12px;
  line-height: 1em;
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
  backface-visibility: hidden;
}
.l-footer__linkDobozListItem a:hover {
  opacity: 0.7;
  transition: 0.3s;
}
.l-footer__logo {
  width: 152px;
}
@media screen and (max-width: 767px) {
  .l-footer__logo {
    width: 45%;
    max-width: 266px;
    margin: 0 auto;
  }
}
.l-footer__logoImg a {
  display: block;
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
  backface-visibility: hidden;
}
.l-footer__logoImg a:hover {
  opacity: 0.7;
  transition: 0.3s;
}
.l-footer__logoImg img {
  max-width: 100%;
}
.l-footer__address {
  text-align: left;
  margin-top: 10px;
}
@media screen and (max-width: 767px) {
  .l-footer__address {
    text-align: center;
    margin-top: 20px;
  }
}
.l-footer__address p {
  font-size: 14px;
  color: #ffffff;
  line-height: 1.71;
}
@media screen and (max-width: 767px) {
  .l-footer__address p {
    font-size: 11px;
  }
}
.l-footer__gnav {
  margin-top: 35px;
}
.l-footer__gnavList {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
}
@media screen and (max-width: 767px) {
  .l-footer__gnavList {
    justify-content: center;
    flex-wrap: wrap;
  }
}
.l-footer__gnavList > li {
  margin-left: 40px;
}
@media screen and (max-width: 767px) {
  .l-footer__gnavList > li {
    width: calc(100% / 3);
    margin-left: 0;
  }
}
.l-footer__gnavList > li:first-of-type {
  margin-left: 0;
}
.l-footer__gnavList a {
  font-size: 14px;
  color: #ffffff !important;
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
  backface-visibility: hidden;
}
.l-footer__gnavList a:hover {
  opacity: 0.7;
  transition: 0.3s;
}
@media screen and (max-width: 767px) {
  .l-footer__gnavList a {
    font-size: 11px;
  }
}
.l-footer__copy {
  font-size: 12px;
  color: #ffffff;
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .l-footer__copy {
    font-size: 10px;
    letter-spacing: 0.4px;
    font-feature-settings: "palt";
  }
}
@media screen and (max-width: 767px) and (-ms-high-contrast: none) {
  .l-footer__copy {
    font-feature-settings: normal;
  }
}

/*============================
.l-container
============================*/
.l-container {
  /* IE11 */
}
_:-ms-lang(x)::-ms-backdrop, .l-container {
  overflow-x: hidden;
  overflow-y: visible;
}

/*============================
.l-section
============================*/
.l-section__inner {
  width: 100%;
  max-width: 1106px;
  margin: 0 auto;
  padding: 0 20px;
}
@media screen and (max-width: 767px) {
  .l-section__inner {
    max-width: 100%;
    padding: 0 20px;
  }
}
.l-section__inner-small {
  max-width: 780px;
}
/*============================
.l-pankuzu
============================*/
.l-pankuzu {
  width: 100%;
  max-width: 1134px;
  margin: 27px auto 0;
  padding: 0 20px;
}
@media screen and (max-width: 767px) {
  .l-pankuzu {
    margin: 20px auto 0;
    padding: 0 10px;
  }
}
.l-pankuzu__list {
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
@media screen and (max-width: 767px) {
  .l-pankuzu__list {
    max-width: 100%;
  }
}
.l-pankuzu__list > li {
  white-space: nowrap;
  font-size: 12px;
}
.l-pankuzu__list > li:last-child {
  text-overflow: ellipsis;
  overflow: hidden;
}
.l-pankuzu__list > li + li {
  margin-left: 10px;
}
.l-pankuzu__list > li a {
  color: #2C2C2C;
}

/*============================
.l-main
============================*/
.l-main {
  margin-top: 130px;
}
@media screen and (max-width: 767px) {
  .l-main {
    margin-top: 65px;
  }
}
.l-main__spacer {
  margin-top: 60px;
  padding-bottom: 160px;
}
@media screen and (max-width: 767px) {
  .l-main__spacer {
    margin-top: 50px;
    padding-bottom: 75px;
  }
}

/*============================
.l-section
============================*/
.l-mv-top {
  width: 100%;
  height: calc(100vh - 130px);
  position: relative;
}
@media screen and (max-width: 767px) {
  .l-mv-top {
    height: auto;
  }
}
.l-mv-top__head {
  position: absolute;
  top: 50%;
  left: 10.6vw;
  bottom: 0;
  transform: translateY(-50%);
  z-index: 10;
}
@media screen and (max-width: 767px) {
  .l-mv-top__head {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 767px) {
  .l-mv-top__head-adj {
    width: 100%;
  }
}
.l-mv-top__headDoboz {
  position: relative;
}
.l-mv-top__headTit {
  text-align: left;
  font-size: 55px;
  color: #ffffff;
  letter-spacing: 0.2em;
}
@media screen and (max-width: 1366px) {
  .l-mv-top__headTit {
    font-size: 4.0263543192vw;
  }
}
@media screen and (max-width: 767px) {
  .l-mv-top__headTit {
    font-size: 60px;
  }
}
@media screen and (max-width: 767px) and (max-width: 767px) {
  .l-mv-top__headTit {
    font-size: 7.8226857888vw;
  }
}
@media screen and (max-width: 767px) {
  .l-mv-top__headTit-sml {
    font-size: 50px;
  }
}
@media screen and (max-width: 767px) and (max-width: 767px) {
  .l-mv-top__headTit-sml {
    font-size: 6.518904824vw;
  }
}
.l-mv-top__headIcon {
  width: 18.2vw;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-30%, -90%);
}
@media screen and (max-width: 767px) {
  .l-mv-top__headIcon {
    width: 33.3vw;
  }
}
.l-mv-top__headIcon img {
  max-width: 100%;
}
@media screen and (max-width: 767px) {
  .l-mv-top__headIcon-sml {
    width: 28vw;
  }
}
.l-mv-top__slider {
  height: 100%;
}
.l-mv-top__slider .slick-list, .l-mv-top__slider .slick-track {
  height: 100%;
}
.l-mv-top__sliderMain {
  height: 100%;
}
.l-mv-top__sliderMainItem {
  height: 100%;
}
.l-mv-top__sliderMainImg {
  height: 100%;
}
.l-mv-top__sliderMainImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
}
.l-mv-top__copy {
  font-size: 12px;
  color: #ffffff;
  font-family: "Oswald", sans-serif;
  position: absolute;
  top: auto;
  left: auto;
  right: 5.4%;
  bottom: 4.2%;
  z-index: 10;
}
@media screen and (max-width: 767px) {
  .l-mv-top__copy {
    display: none;
  }
}
.l-mv-top .m-scroll {
  position: absolute;
  top: auto;
  left: 5%;
  bottom: 0;
  z-index: 10;
}
@media screen and (max-width: 767px) {
  .l-mv-top .m-scroll {
    display: none;
  }
}
.l-mv__img {
  width: 100%;
  height: 400px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .l-mv__img {
    height: auto;
    position: relative;
    overflow: hidden;
  }
  .l-mv__img::after {
    content: "";
    display: block;
    padding-top: 50.6666666667%;
  }
  .l-mv__img img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: top;
    font-family: "object-fit:cover;";
  }
}
.l-mv__imgBk {
  width: 100%;
  height: 100%;
}
.l-mv__imgBk img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
}
.l-mv__imgBk-gray {
  background-color: #F4F4F4;
}
.l-mv__imgTit {
  width: 100%;
  font-size: 55px;
  color: #ffffff;
  line-height: 1.4em;
  letter-spacing: 0.2em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 767px) {
  .l-mv__imgTit {
    font-size: 30px;
  }
}
.l-mv__imgTit-black {
  color: #2C2C2C;
}
.l-mv__nav {
  background-color: #EE8A18;
  padding: 20px 0;
}
@media screen and (max-width: 767px) {
  .l-mv__nav {
    padding: 10px 0 5px 20px;
  }
}
.l-mv__navList {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .l-mv__navList {
    justify-content: flex-start;
    overflow: scroll;
    white-space: nowrap;
    padding-right: 20px;
    padding-bottom: 5px;
  }
}
@media screen and (max-width: 767px) {
  .l-mv__navList > li {
    display: inline-block;
  }
}
.l-mv__navList > li + li {
  margin-left: 40px;
}
@media screen and (max-width: 767px) {
  .l-mv__navList > li + li {
    margin-left: 20px;
  }
}
.l-mv__navList a {
  font-size: 14px;
  color: #ffffff !important;
}
@media screen and (min-width: 768px) and (max-width: 1366px) {
  .l-mv__navList a {
    font-size: 1.0248901903vw;
  }
}
@media screen and (max-width: 767px) {
  .l-mv__navList a {
    font-size: 12px;
  }
}
.l-mv__navList a:hover {
  border-bottom: 1px solid #ffffff;
  padding-bottom: 3px;
}
.l-mv__navList-adj > li + li {
  margin-left: 20px;
}
@media screen and (max-width: 767px) {
  .l-mv__navList-adj > li + li {
    margin-left: 20px;
  }
}
.l-mv__navList .is-active {
  border-bottom: 1px solid #ffffff;
  padding-bottom: 3px;
}

/*============================
.l-footcontact
============================*/
.l-footcontact__doboz {
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
  backface-visibility: hidden;
  background: url(../img/global/footcontact/bk.jpg) no-repeat center;
  background-size: cover;
  height: 390px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.l-footcontact__doboz:hover {
  opacity: 0.7;
  transition: 0.3s;
}
@media screen and (max-width: 767px) {
  .l-footcontact__doboz {
    height: 175px;
    background: url(../img/global/footcontact/sp-bk.jpg) no-repeat center;
    background-size: cover;
  }
}
.l-footcontact .m-tit__ja {
  font-size: 26px;
}
@media screen and (max-width: 767px) {
  .l-footcontact .m-tit__ja {
    font-size: 20px;
  }
}
.l-footcontact .m-tit__en {
  font-size: 12px;
  margin-top: 10px;
}
@media screen and (max-width: 767px) {
  .l-footcontact .m-tit__en {
    margin-top: 5px;
  }
}
.l-footcontact__desc {
  margin-top: 30px;
}
@media screen and (max-width: 767px) {
  .l-footcontact__desc {
    margin-top: 15px;
  }
}
.l-footcontact__desc p {
  font-size: 17px;
  color: #ffffff;
}
@media screen and (max-width: 767px) {
  .l-footcontact__desc p {
    font-size: 12px;
  }
}
.l-footcontact__icon {
  margin-top: 35px;
}
@media screen and (max-width: 767px) {
  .l-footcontact__icon {
    margin-top: 10px;
    width: 20px;
  }
}
@media screen and (max-width: 767px) {
  .l-footcontact__icon img {
    max-width: 100%;
  }
}

/*============================
.l-linklist
============================*/
.l-link__list {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}
@media screen and (max-width: 767px) {
  .l-link__list {
    display: block;
  }
}
.l-link__listItem {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .l-link__listItem {
    width: 100%;
  }
}
.l-link__listItem-full {
  width: 100%;
}
.l-link__listItem-full .l-link__listTit {
  left: 3.6%;
}
@media screen and (max-width: 767px) {
  .l-link__listItem-full .l-link__listTit {
    left: 20px;
  }
}
.l-link__listItem-full .l-link__listBkIcon {
  right: 3.6%;
}
@media screen and (max-width: 767px) {
  .l-link__listItem-full .l-link__listBkIcon {
    right: 20px;
  }
}
.l-link__listItem a {
  display: block;
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
  backface-visibility: hidden;
}
.l-link__listItem a:hover {
  opacity: 0.7;
  transition: 0.3s;
}
.l-link__listBk {
  position: relative;
}
.l-link__listBkImg {
  height: 100%;
}
.l-link__listBkImg img {
  width: 100%;
}
.l-link__listBkIcon {
  position: absolute;
  top: auto;
  left: auto;
  right: 10%;
  bottom: 15%;
}
@media screen and (max-width: 767px) {
  .l-link__listBkIcon {
    width: 20px;
    right: 20px;
    bottom: 20px;
  }
}
.l-link__listBkIcon img {
  max-width: 100%;
}
.l-link__listTit {
  text-align: left;
  position: absolute;
  top: auto;
  left: 10%;
  bottom: 15%;
}
@media screen and (max-width: 767px) {
  .l-link__listTit {
    left: 20px;
    bottom: 20px;
  }
}
.l-link__listTitJa {
  font-size: 24px;
  color: #ffffff;
}
@media screen and (min-width: 768px) and (max-width: 1366px) {
  .l-link__listTitJa {
    font-size: 1.756954612vw;
  }
}
@media screen and (max-width: 767px) {
  .l-link__listTitJa {
    font-size: 20px;
  }
}
.l-link__listTitEn {
  display: block;
  font-size: 14px;
  font-size: 14px;
  color: #ffffff;
  font-family: "Oswald", sans-serif;
}
@media screen and (min-width: 768px) and (max-width: 1366px) {
  .l-link__listTitEn {
    font-size: 1.0248901903vw;
  }
}
@media screen and (max-width: 767px) {
  .l-link__listTitEn {
    font-size: 13px;
  }
}
.l-link__listTitSup {
  display: block;
  font-size: 16px;
  color: #ffffff;
}
@media screen and (min-width: 768px) and (max-width: 1366px) {
  .l-link__listTitSup {
    font-size: 1.1713030747vw;
  }
}
@media screen and (max-width: 767px) {
  .l-link__listTitSup {
    font-size: 11px;
  }
}
.l-link__listTitSup-under {
  padding-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .l-link__listTitSup-under {
    padding-bottom: 0px;
  }
}
.l-link__listTitSup-under::after {
  content: "";
  display: block;
  width: 25px;
  height: 1px;
  background-color: #ffffff;
  margin-top: 10px;
}
@media screen and (max-width: 767px) {
  .l-link__listTitSup-under::after {
    width: 12px;
    margin-top: 5px;
  }
}
.l-link__listTitMain {
  display: block;
  font-size: 24px;
  color: #ffffff;
}
@media screen and (min-width: 768px) and (max-width: 1366px) {
  .l-link__listTitMain {
    font-size: 1.756954612vw;
  }
}
@media screen and (max-width: 767px) {
  .l-link__listTitMain {
    font-size: 17px;
  }
}
.l-link__listTitSub {
  display: block;
  font-size: 18px;
  color: #ffffff;
}
@media screen and (min-width: 768px) and (max-width: 1366px) {
  .l-link__listTitSub {
    font-size: 1.317715959vw;
  }
}
@media screen and (max-width: 767px) {
  .l-link__listTitSub {
    font-size: 11px;
  }
}
.l-link__listTit-hasicon .l-link__listTitJa {
  position: relative;
}
.l-link__listTit-hasicon .l-link__listTitJa::after {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background: url(../img/global/link_external_2.svg) no-repeat center;
  background-size: contain;
  position: absolute;
  top: 35%;
  left: auto;
  right: -25px;
}
.l-link__listTxt {
  padding: 30px 50px 70px;
  text-align: left;
}
@media screen and (max-width: 767px) {
  .l-link__listTxt {
    padding: 15px 20px 30px;
  }
}
.l-link__listTxt p {
  font-size: 16px;
  line-height: 1.87;
}
@media screen and (max-width: 767px) {
  .l-link__listTxt p {
    font-size: 12px;
    line-height: 1.75;
  }
}
.l-link__listDesc {
  padding: 30px 10% 70px;
  text-align: left;
}
@media screen and (max-width: 767px) {
  .l-link__listDesc {
    padding: 15px 20px 30px;
  }
}
.l-link__listDescTit {
  font-size: 21px;
  line-height: 1.66;
}
@media screen and (min-width: 768px) and (max-width: 1366px) {
  .l-link__listDescTit {
    font-size: 1.5373352855vw;
  }
}
@media screen and (max-width: 767px) {
  .l-link__listDescTit {
    font-size: 14px;
  }
}
.l-link__listDescTxt {
  margin-top: 10px;
}
@media screen and (max-width: 767px) {
  .l-link__listDescTxt {
    margin-top: 5px;
  }
}
.l-link__listDescTxt p {
  font-size: 16px;
  line-height: 1.87;
}
@media screen and (min-width: 768px) and (max-width: 1366px) {
  .l-link__listDescTxt p {
    font-size: 1.1713030747vw;
  }
}
@media screen and (max-width: 767px) {
  .l-link__listDescTxt p {
    font-size: 12px;
    line-height: 1.75;
  }
}

/*============================
.l-footlink-recruit
============================*/
.l-footlink-recruit {
  margin-top: 120px;
}
@media screen and (max-width: 767px) {
  .l-footlink-recruit {
    margin-top: 75px;
  }
}
@media screen and (max-width: 767px) {
  .l-footlink-recruit .l-link__listItem:first-of-type .l-link__listTxt {
    display: none;
  }
}

/*============================
.l-information
============================*/
.l-information__list {
  border-bottom: 1px solid #E0E0E0;
}
.l-information__listItem {
  border-top: 1px solid #E0E0E0;
}
.l-information__list a {
  display: block;
  padding: 25px 0;
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
  backface-visibility: hidden;
}
.l-information__list a:hover {
  opacity: 0.7;
  transition: 0.3s;
}
@media screen and (max-width: 767px) {
  .l-information__list a {
    padding: 15px 20px;
  }
}
.l-information__listFlx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .l-information__listFlx {
    display: block;
  }
}
.l-information__listFlxSplit:nth-of-type(1) {
  width: 290px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .l-information__listFlxSplit:nth-of-type(1) {
    width: 100%;
  }
}
.l-information__listFlxSplit:nth-of-type(2) {
  width: calc(100% - 290px);
}
@media screen and (max-width: 767px) {
  .l-information__listFlxSplit:nth-of-type(2) {
    width: 100%;
  }
}
.l-information__listDate {
  width: 110px;
  font-size: 13px;
  color: #BABABA;
  margin-right: 35px;
}
@media screen and (max-width: 767px) {
  .l-information__listDate {
    font-size: 11px;
    margin-right: 15px;
  }
}
.l-information__listLabel {
  display: inline-block;
  width: 116px;
  background-color: #EE8A18;
  border-radius: 20px;
  font-size: 13px;
  color: #ffffff;
  line-height: 1em;
  padding: 10px 20px;
}
@media screen and (max-width: 767px) {
  .l-information__listLabel {
    min-width: 80px;
    font-size: 10px;
    padding: 5px 10px;
  }
}
.l-information__listLabel-orange {
  color: #EE8A18;
  background-color: #ffffff;
  border: 1px solid #EE8A18;
}
.l-information__listTit {
  font-size: 16px;
  line-height: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 100px;
  text-align: left;
}
@media screen and (max-width: 767px) {
  .l-information__listTit {
    font-size: 12px;
    padding-right: 0;
    margin-top: 10px;
  }
}

/*============================
.l-single-post
============================*/
.l-single-post {
  text-align: left;
}
.l-single-post .single__body > ul > li > ul,
.l-single-post nav ol,
.l-single-post nav ul {
  list-style: none;
}
.l-single-post .single__body a:hover {
  text-decoration: none;
}
.l-single-post .single__body h4 + p {
  margin-top: 0;
}
.l-single-post .single__body i {
  font-weight: 700;
}
@media all and (min-width: 769px) {
  .l-single-post .single__header__upper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
  }
}
.l-single-post .single__header__bottom {
  margin-top: 30px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__header__bottom {
    margin-top: 45px;
  }
}
.l-single-post .single__meta {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.l-single-post .single__meta .date-stamp {
  letter-spacing: 0.075em;
  color: #676767;
  font-size: 0.85714rem;
}
@media all and (min-width: 769px) {
  .l-single-post .single__meta .date-stamp {
    font-size: 0.85714rem;
  }
}
.l-single-post .single__category-stamp {
  width: 71px;
  height: 18px;
  margin-left: 15px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__category-stamp {
    width: 81px;
    height: 24px;
  }
}
.l-single-post .single__body h2,
.l-single-post .single__body h3,
.l-single-post .single__sns {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
@media all and (max-width: 768px) {
  .l-single-post .single__sns {
    margin-top: 13px;
  }
}
.l-single-post .single__sns .fb_iframe_widget > span {
  vertical-align: middle !important;
}
.l-single-post .single__sns__btn {
  margin-left: 2px;
  margin-right: 2px;
}
.l-single-post .single__sns__btn--twitter a {
  display: block;
  width: 78px;
  height: 28px;
  position: relative;
  background-color: #4faaf0;
  text-align: center;
}
.l-single-post .single__sns__btn--twitter .icon-twitter:before {
  position: absolute;
  top: 5px;
  left: 30px;
  font-size: 18px;
  color: #fff;
}
.l-single-post .single__sns__btn--hatebu a {
  display: block;
  width: 78px;
  height: 28px;
  position: relative;
  background-color: #00a3df;
}
.l-single-post .single__sns__btn--hatebu .icon-hatebu:before {
  position: absolute;
  top: 7px;
  left: 32px;
  font-size: 14px;
  color: #fff;
}
.l-single-post .single__subtitle {
  font-weight: 700;
  color: #676767;
  letter-spacing: 0.075em;
  font-size: 12px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__subtitle {
    color: #333;
    letter-spacing: 0.2em;
    font-size: 18px;
  }
}
.l-single-post .single__headline {
  margin-top: 10px;
  color: #141316;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1rem;
  line-height: 24px;
  letter-spacing: 0.075em;
  font-size: 20px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__headline {
    font-size: 2rem;
    line-height: 1.375rem;
    margin-top: 20px;
    line-height: 40px;
    letter-spacing: 0.2em;
    font-size: 32px;
  }
}
.l-single-post .single__sns__btn--line {
  width: 83px;
  height: 28px;
  background-color: #00b900;
  cursor: pointer;
}
.l-single-post .single__eyecatch {
  display: block;
  position: relative;
  margin-top: 20px;
  text-align: center;
}
@media all and (min-width: 769px) {
  .l-single-post .single__eyecatch {
    margin-top: 32px;
  }
}
.l-single-post .single__eyecatch.single__eyecatch--seeahead {
  margin-bottom: 20px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__eyecatch.single__eyecatch--seeahead {
    margin-top: 60px;
    margin-bottom: 50px;
  }
}
.l-single-post .single__eyecatch.single__eyecatch--seeahead .single__eyecatch__image {
  width: 80%;
}
@media all and (min-width: 769px) {
  .l-single-post .single__eyecatch.single__eyecatch--seeahead .single__eyecatch__image {
    width: 50%;
  }
}
.l-single-post .single__eyecatch__image {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.l-single-post .single__eyecatch__read {
  margin-top: 20px;
  color: #333;
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-size: 12px;
  letter-spacing: 0.01em;
}
@media all and (min-width: 769px) {
  .l-single-post .single__eyecatch__read {
    font-size: 0.875rem;
    line-height: 1.75rem;
    margin-top: 15px;
    font-size: 14px;
  }
}
.l-single-post .single__body {
  margin-top: 25px;
  padding-bottom: 20px;
  font-size: 0.875rem;
  line-height: 1.625rem;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body {
    font-size: 1.0625rem;
    line-height: 2.125rem;
    margin-top: 45px;
    padding-bottom: 50px;
    font-size: 14px;
  }
}
.l-single-post .single__body h2,
.l-single-post .single__body h3 {
  position: relative;
  color: #333;
  font-weight: 700;
  letter-spacing: 0.2em;
}
.l-single-post .single__body h2 {
  padding: 0 0 5px;
  border-bottom: 1px solid #b5b5b5;
  margin: 20px 0 15px;
  font-size: 1rem;
  line-height: 2rem;
  font-size: 16px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body h2 {
    font-size: 1.75rem;
    line-height: 2rem;
    padding: 0 0 18px;
    margin: 60px 0 30px;
    font-size: 28px;
  }
}
.l-single-post .single__body h2:before {
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 1px;
  width: 100%;
  background-color: #b5b5b5;
  content: "";
}
.l-single-post .single__body h3 {
  margin: 25px 0 10px;
  line-height: 26px;
  font-size: 0.875rem;
  line-height: 1rem;
  font-size: 14px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body h3 {
    font-size: 1.375rem;
    line-height: 2rem;
    margin: 40px 0 20px;
    font-size: 22px;
  }
}
.l-single-post .single__body h4 {
  margin: 40px 15px 10px;
  color: #333;
  font-size: 1.21429rem;
  font-weight: 700;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body h4 {
    margin: 40px 0 10px;
    font-size: 20px;
  }
  .l-single-post .single__body h4 + p {
    margin-top: 0;
  }
}
.l-single-post .single__body table {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  border-top: 1px solid #d2d2d2;
  border-collapse: separate;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body table {
    margin: 40px 0;
  }
}
.l-single-post .single__body table td,
.l-single-post .single__body table th {
  border-bottom: 1px solid #d2d2d2;
  text-align: left;
  color: #000;
  font-size: 0.75rem;
  line-height: 1.5rem;
  letter-spacing: 0.075em;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body table td,
.l-single-post .single__body table th {
    font-size: 0.875rem;
    line-height: 1.5rem;
    padding: 20px 25px;
  }
}
.l-single-post .single__body table th {
  padding: 18px 18px 18px 0;
  border-right: 1px solid #d2d2d2;
  font-weight: 700;
  font-size: 12px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body table th {
    font-size: 14px;
    width: 192px;
  }
}
.l-single-post .single__body table td {
  padding: 18px 0 18px 18px;
  font-size: 12px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body table td {
    padding: 18px 0 18px 28px;
    font-size: 14px;
  }
}
.l-single-post .single__body .horizontal tr:last-child th,
.l-single-post .single__body .vertical tr:last-child th {
  border-bottom: 1px solid #ee8a18;
}
.l-single-post .single__body .vertical th {
  width: 245px;
  border-bottom: 1px solid #fff;
}
.l-single-post .single__body .horizontal {
  table-layout: fixed;
}
.l-single-post .single__body .horizontal td,
.l-single-post .single__body .horizontal th {
  width: 50%;
}
.l-single-post .single__body .horizontal th {
  border-bottom: 1px solid #fff;
}
.l-single-post .single__body .horizontal th ~ th {
  border-left: 1px solid #fff;
  border-right: 1px solid #ee8a18;
}
.l-single-post .single__body > ul {
  margin: 30px 0;
  list-style: none;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body > ul {
    margin: 40px 0;
  }
}
.l-single-post .single__body > ul > li {
  letter-spacing: 0.2em;
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-size: 12px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body > ul > li {
    font-size: 0.875rem;
    line-height: 2rem;
    padding-left: 17px;
    font-weight: 700;
    font-size: 14px;
  }
}
.l-single-post .single__body > ul > li:before,
.l-single-post .single__body > ul > li > ul > li:before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  position: absolute;
  top: 9px;
}
.l-single-post .single__body > ul > li:before {
  background: #333;
  left: 0;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body > ul > li:before {
    top: 12px;
  }
}
@media all and (max-width: 768px) {
  .l-single-post .single__body > ul > li > ul {
    margin-left: 12px;
  }
}
.l-single-post .single__body > ul > li,
.l-single-post .single__body > ul > li > ul > li {
  position: relative;
  padding-left: 15px;
  margin-top: 2px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body > ul > li > ul > li {
    padding-left: 21px;
    font-size: 14px;
  }
}
.l-single-post .single__body > ul > li > ul > li:before {
  border: 1px solid #333;
  left: 4px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body > ul > li > ul > li:before {
    top: 12px;
  }
}
.l-single-post .single__body ol {
  margin: 20px 0 20px 3px;
  list-style: none;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body ol {
    margin: 40px 0;
  }
}
.l-single-post .single__body ol > li,
.l-single-post .single__body p {
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-size: 12px;
}
.l-single-post .single__body ol > li {
  position: relative;
  padding-left: 22px;
  counter-increment: number;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body ol > li {
    font-size: 0.875rem;
    line-height: 2rem;
    padding-left: 19px;
    font-size: 14px;
  }
}
.l-single-post .single__body ol > li:before,
.l-single-post .single__body ol > li > ol > li:before {
  display: inline-block;
  position: absolute;
  color: #333;
  font-weight: 700;
}
.l-single-post .single__body ol > li:before {
  line-height: 1;
  content: counter(number) ".";
  top: 10px;
  left: -1px;
  font-size: 12px;
}
.l-single-post .single__body ol > li > ol > li {
  position: relative;
  margin-top: 10px;
  padding-left: 14px;
  font-weight: 700;
  counter-increment: number-inner;
}
.l-single-post .single__body ol > li > ol > li:before {
  content: counter(number-inner) ".";
  left: -9px;
  top: 1px;
  background-color: transparent;
  font-size: 16px;
}
.l-single-post .single__body p {
  margin: 10px 0;
  overflow: hidden;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body p {
    font-size: 0.875rem;
    line-height: 1.75rem;
    margin: 20px 0;
    font-size: 14px;
  }
}
.l-single-post .single__body a {
  display: inline-block;
  position: relative;
  color: #ee8a18;
  font-weight: 700;
  text-decoration: underline;
}
.l-single-post .single__body a[target=_blank] {
  position: relative;
  padding-right: 10px;
}
.l-single-post .single__body a[target=_blank]:after {
  display: inline-block;
  font-family: "icomoon";
  position: relative;
  left: 3px;
  top: 0;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  content: "\e908";
}
.l-single-post .single__body strong {
  font-weight: 700;
  background: linear-gradient(transparent 40%, rgba(255, 207, 153, 0.5) 40%);
}
.l-single-post .single__body small {
  font-size: 12px;
  font-weight: 400;
}
.l-single-post .page-ir-lower__table th,
.l-single-post .single__body small a {
  font-weight: 400;
}
.l-single-post .single__body img {
  max-width: 100%;
  height: auto;
  padding: 0 15px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body img {
    padding: 0;
  }
}
.l-single-post .single__body .single__iframe {
  position: relative;
  width: 100%;
  padding: 71.5% 0 0;
  margin: 40px 15px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body .single__iframe {
    padding: 56.2% 0 0;
    margin: 40px 0;
  }
}
.l-single-post .single__body .single__iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.l-single-post .single__body .note {
  position: relative;
  padding: 20px 15px;
  margin-top: 40px;
  margin-bottom: 40px;
  line-height: 2;
  border: 1px solid #dbdada;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body .note {
    margin: 40px 0;
    padding: 25px 70px 25px 90px;
  }
}
.l-single-post .single__body .note:before {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 12px;
  height: 9px;
  background: url(../images/icon-quart.png) no-repeat 0 0;
  background-size: 12px auto;
  content: "";
}
@media all and (min-width: 769px) {
  .l-single-post .single__body .note:before {
    top: 34px;
    left: 40px;
    width: 25px;
    height: 22px;
    background-size: 25px auto;
  }
}
.l-single-post .single__body .note p {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1rem;
}
.l-single-post .single__body .note p:last-child {
  margin-top: 30px;
  margin-bottom: 0;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body .note p {
    font-size: 1.21429rem;
  }
}
.l-single-post .single__body blockquote {
  position: relative;
  padding: 50px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f4f4f4;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body blockquote {
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 58px 60px 50px;
  }
}
.l-single-post .single__body blockquote:after,
.l-single-post .single__body blockquote:before {
  width: 20px;
  height: 18px;
  position: absolute;
  background: url(../images/icon-block-quote.png) no-repeat 0 0;
  background-size: 20px 18px;
  content: "";
}
@media all and (min-width: 769px) {
  .l-single-post .single__body blockquote:after,
.l-single-post .single__body blockquote:before {
    width: 28px;
    height: 26px;
    background-size: 28px 26px;
  }
}
.l-single-post .single__body blockquote:before {
  top: 20px;
  left: 20px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body blockquote:before {
    top: 20px;
    left: 20px;
  }
}
.l-single-post .single__body blockquote:after {
  bottom: 20px;
  right: 20px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
@media all and (min-width: 769px) {
  .l-single-post .single__body blockquote:after {
    bottom: 20px;
    right: 20px;
  }
}
.l-single-post .single__body blockquote p {
  margin-top: 0;
  margin-bottom: 10px;
}
.l-single-post .single__body blockquote p:last-child {
  margin-bottom: 0;
}
.l-single-post .single__body .pdf-link a {
  display: block;
  margin: 30px 0;
  padding: 10px;
  border: 1px solid #d2d2d2;
  font-weight: 400;
  text-decoration: none;
  text-align: center;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body .pdf-link a {
    display: inline-block;
    margin: 30px 0;
    padding: 11px 37px;
  }
}
.l-single-post .single__body .pdf-link .pdf-link-text {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-size: 11px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body .pdf-link .pdf-link-text {
    font-size: 14px;
  }
}
.l-single-post .single__body .pdf-link .pdf-link-text .icon-pdf {
  position: absolute;
  top: 50%;
  right: -20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #c91111;
  font-size: 18px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body .pdf-link .pdf-link-text .icon-pdf {
    font-size: 21px;
    right: -30px;
  }
}
.l-single-post .single__body .pdf-link .pdf-byte {
  display: inline-block;
  vertical-align: middle;
  padding-left: 25px;
  font-size: 9px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body .pdf-link .pdf-byte {
    padding-left: 50px;
    font-size: 12px;
  }
}
.l-single-post .single__body .movie-link a {
  display: block;
  margin: 30px 0;
  padding: 10px 25px 10px 10px;
  border: 1px solid #d2d2d2;
  font-weight: 400;
  text-decoration: none;
  text-align: center;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body .movie-link a {
    display: inline-block;
    padding: 11px 50px 11px 37px;
  }
}
.l-single-post .single__body .movie-link .movie-link-text {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-size: 11px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body .movie-link .movie-link-text {
    font-size: 14px;
  }
}
.l-single-post .single__body .movie-link .movie-link-text .icon-movie {
  position: absolute;
  top: calc(50% + 2px);
  right: -25px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #c91111;
  font-size: 14px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body .movie-link .movie-link-text .icon-movie {
    top: calc(50% + 1px);
    font-size: 16px;
    right: -30px;
  }
}
.l-single-post .single__body .alignnone {
  display: block;
  margin-top: 10px;
}
.l-single-post .single__body .aligncenter,
.l-single-post .single__body .alignleft,
.l-single-post .single__body .alignright {
  display: block;
}
.l-single-post .single__body .aligncenter,
.l-single-post .single__body .alignnone {
  margin-left: auto;
  margin-right: auto;
}
.l-single-post .single__body .alignright {
  margin-left: auto;
  margin-right: 0;
}
.l-single-post .single__body .alignleft {
  margin-left: 0;
  margin-right: auto;
}
.l-single-post .single__body p.wp-caption-text {
  margin-top: 15px;
}
.l-single-post .single__body .aligncenter,
.l-single-post .single__body .alignleft,
.l-single-post .single__body .alignright,
.l-single-post .single__body h2 + .alignnone {
  margin-top: 0;
}
@media all and (min-width: 769px) {
  .l-single-post .single__body .aligncenter,
.l-single-post .single__body .alignleft,
.l-single-post .single__body .alignright,
.l-single-post .single__body h2 + .alignnone {
    margin-top: 50px;
  }
}
.l-single-post .single__author {
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 38px;
}
.l-single-post .single__author,
.l-single-post .single__author__detail {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.l-single-post .single__author__image {
  display: block;
  width: 60px;
  height: 60px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__author__image {
    width: 141px;
    height: 141px;
  }
}
.l-single-post .single__author__profile {
  padding-left: 20px;
}
.l-single-post .single__author__name__position {
  display: block;
  font-size: 1rem;
}
.l-single-post .single__author__name {
  display: block;
  font-size: 1.14286rem;
  font-weight: 700;
}
.l-single-post .single__author__name-image {
  width: 187px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__author__name-image {
    width: 374px;
  }
}
.l-single-post .single__nav {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 66px;
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
}
@media all and (min-width: 769px) {
  .l-single-post .single__nav {
    height: 156px;
  }
}
.l-single-post .single__nav__item {
  display: block;
  position: relative;
  width: 50%;
  padding: 15px 0;
  color: #333;
}
@media all and (min-width: 769px) {
  .l-single-post .single__nav__item {
    padding: 35px 0;
  }
}
.l-single-post .single__nav__item ~ .single__nav__item:before {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 1px;
  height: 35px;
  background-color: #d2d2d2;
  content: "";
}
@media all and (min-width: 769px) {
  .l-single-post .single__nav__item ~ .single__nav__item:before {
    height: 100%;
  }
  .l-single-post .single__nav__item.single__nav__item--prev {
    padding-right: 35px;
  }
  .l-single-post .single__nav__item.single__nav__item--next {
    padding-left: 35px;
  }
}
.l-single-post .single__nav__item__text {
  position: relative;
  font-weight: 700;
  text-align: center;
}
@media all and (max-width: 768px) {
  .l-single-post .single__nav__item__text {
    padding: 4px 0;
    font-size: 12px;
  }
}
@media all and (min-width: 769px) {
  .l-single-post .single__nav__item__text.single__nav__item__text--prev {
    text-align: left;
    padding-left: 23px;
  }
  .l-single-post .single__nav__item__text.single__nav__item__text--next {
    text-align: right;
    padding-right: 23px;
  }
}
.l-single-post .single__nav__item__text.is-disable {
  color: #b2b2b2;
}
@media all and (min-width: 769px) {
  .l-single-post .single__nav__item__text.is-disable {
    display: none;
  }
}
.l-single-post .single__nav__item__text .icon-arrow-left,
.l-single-post .single__nav__item__text .icon-arrow-right {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 22px;
  width: 14px;
}
.l-single-post .single__nav__item__text .icon-arrow-left {
  left: 0;
}
.l-single-post .single__nav__item__text .icon-arrow-right {
  right: 0;
}
.l-single-post .single__nav__item__title {
  line-height: 28px;
}
.l-single-post .single__nav__item__title.single__nav__item__title--prev {
  text-align: left;
  margin-top: 7px;
}
.l-single-post .single__nav__item__title.single__nav__item__title--next .single__nav__item__title__date,
.l-single-post .single__nav__item__title.single__nav__item__title--next .single__nav__item__title__inner,
.l-single-post .single__nav__item__title.single__nav__item__title--prev .single__nav__item__title__date,
.l-single-post .single__nav__item__title.single__nav__item__title--prev .single__nav__item__title__inner {
  padding-left: 8px;
}
.l-single-post .single__nav__item__title.single__nav__item__title--next {
  text-align: right;
  margin-top: 5px;
}
.l-single-post .single__nav__item__title__date,
.l-single-post .single__nav__item__title__inner,
.l-single-post .single__nav__item__title__vol {
  font-size: 18px;
  letter-spacing: 0.01em;
}
.l-single-post .single__nav__btn {
  margin: 25px auto 0;
}
@media all and (min-width: 769px) {
  .l-single-post .single__nav__btn {
    width: 320px;
    margin: 40px auto 0;
  }
}
.l-single-post .single__footer-btn {
  margin-top: 25px;
  height: 43px;
}
@media all and (min-width: 769px) {
  .l-single-post .single__footer-btn {
    width: 320px;
    height: 56px;
    margin: 40px auto 0;
  }
}

@font-face {
  font-family: "icomoon";
  src: url(../lib/fonts/icomoon.eot);
  src: url(../lib/fonts/icomoon.eot) format("embedded-opentype"), url(../lib/fonts/icomoon.ttf) format("truetype"), url(../lib/fonts/icomoon.woff) format("woff"), url(../lib/fonts/icomoon.svg) format("svg");
  font-weight: 400;
  font-style: normal;
}
.l-old-section [class*=" icon-"]:before, .l-old-section [class^=icon-]:before {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media all and (min-width: 769px) {
  .l-old-section .sp-only {
    display: none !important;
  }
}
@media all and (max-width: 768px) {
  .l-old-section .pc-only {
    display: none !important;
  }
}
.l-old-section .icon-triangle:before {
  content: "\e90b";
}
.l-old-section .icon-movie:before {
  content: "\e909";
}
.l-old-section .icon-pdf:before {
  content: "\e90a";
}
.l-old-section .icon-arrow-left:before {
  content: "\e900";
}
.l-old-section .icon-arrow-right:before {
  content: "\e901";
}
.l-old-section .icon-arrow-left-large:before {
  content: "\e906";
}
.l-old-section .icon-arrow-right-large:before {
  content: "\e907";
}
.l-old-section .icon-earth:before {
  content: "\e902";
}
.l-old-section .icon-home:before {
  content: "\e903";
}
.l-old-section .icon-mail:before {
  content: "\e904";
}
.l-old-section .icon-tell:before {
  content: "\e905";
}
.l-old-section .icon-blank:before {
  content: "\e908";
}
.l-old-section .btn.btn--white:hover .btn__text,
.l-old-section .pull-down__nav__list > li > a:hover,
.l-old-section .pull-down__nav__list > li > ul > li > a:hover {
  color: #ee8a18;
}
.l-old-section .btn,
.l-old-section .btn:hover {
  text-decoration: none;
}
.l-old-section .btn {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  letter-spacing: 0.08em;
  font-weight: 700;
  border-radius: 28px;
  cursor: pointer;
  transition: background-color 0.3s cubic-bezier(0.23, 0.76, 0.37, 0.99);
  background-color: #ee8a18;
}
.l-old-section .btn:hover {
  background-color: #c57915;
}
.l-old-section .btn:disabled {
  background-color: #d9d8d8 !important;
  color: #fff !important;
  cursor: not-allowed;
}
.l-old-section .btn:disabled .icon-arrow-left,
.l-old-section .btn:disabled .icon-arrow-right {
  background-color: #fff;
}
.l-old-section .btn:disabled .icon-arrow-left:before,
.l-old-section .btn:disabled .icon-arrow-right:before {
  color: #d9d8d8 !important;
}
.l-old-section .btn.is-disable {
  pointer-events: none;
}
.l-old-section .btn.is-disable * {
  background-color: #333 !important;
}
.l-old-section .btn .icon-arrow-left:before,
.l-old-section .btn .icon-arrow-right:before {
  position: absolute;
  top: 50%;
  font-size: 0.78571rem;
  font-weight: 700;
  color: #fff;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.l-old-section .btn .icon-arrow-right:before {
  transition: color 0.3s cubic-bezier(0.23, 0.76, 0.37, 0.99);
  right: 6%;
}
.l-old-section .btn .icon-arrow-left:before {
  right: auto;
  left: 6%;
}
.l-old-section .btn .icon-arrow-down:before {
  -webkit-transform: translate(0, -50%) rotate(90deg);
  transform: translate(0, -50%) rotate(90deg);
}
.l-old-section .btn.btn--white {
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
}
@media all and (min-width: 769px) {
  .l-old-section .btn.btn--white {
    border: 2px solid #fff;
  }
}
.l-old-section .btn.btn--white:hover {
  background-color: #fff;
}
.l-old-section .btn.btn--white:hover .icon-arrow-right:before,
.l-old-section .news__list > li a:hover * {
  color: #ee8a18;
}
.l-old-section .btn.btn--hover-purple:hover .btn__text,
.l-old-section .btn.btn--hover-purple:hover .icon-arrow-right:before {
  color: #363382;
}
.l-old-section .btn-submit {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  height: 100%;
  width: 100%;
  opacity: 0;
}
.l-old-section .btn__text {
  position: absolute;
  top: 50%;
  left: 50%;
  white-space: nowrap;
  font-weight: 700;
  font-size: 12px;
  color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: color 0.3s cubic-bezier(0.23, 0.76, 0.37, 0.99);
}
@media all and (min-width: 769px) {
  .l-old-section .btn__text {
    font-size: 14px;
  }
}

/*============================
.l-yearly-pager
============================*/
.l-yearly-pager .pager-sp__select form,
.l-yearly-pager .yearly-pager-sp__select form {
  display: block;
  height: 100%;
}
.l-yearly-pager .pager-sp__select select,
.l-yearly-pager .yearly-pager-sp__select select {
  position: relative;
  z-index: 1;
  opacity: 0;
  height: 35px;
  width: 100%;
}
.l-yearly-pager .pager-sp__select__num {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  padding: 11px 0;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.075em;
  background: url(../images/select-pager-bg.png) no-repeat calc(50% + 37px) center;
  background-size: 12px 8px;
}
@media all and (min-width: 769px) {
  .l-yearly-pager .yearly-pager {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 70px;
    margin-left: -10px;
    margin-right: -10px;
  }
}
.l-yearly-pager .yearly-pager__arrow {
  width: 60px;
  height: 40px;
  background-color: #fff;
  color: #707070;
  border: 1px solid #e5e5e5;
  font-size: 14px;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 7px;
  line-height: 1;
  transition: background-color 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99), color 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99);
}
.l-yearly-pager .yearly-pager__arrow:hover {
  background-color: #eee;
  color: #707070;
  border: 1px solid #e5e5e5;
}
.l-yearly-pager .yearly-pager__arrow.yearly-pager__arrow--left {
  margin-right: 20px;
}
.l-yearly-pager .yearly-pager__arrow.yearly-pager__arrow--right {
  margin-left: 20px;
}
.l-yearly-pager .yearly-pager__nav {
  margin-left: 5px;
  margin-right: 5px;
}
@media all and (min-width: 769px) {
  .l-yearly-pager .yearly-pager__nav {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.l-yearly-pager .yearly-pager__nav--next,
.l-yearly-pager .yearly-pager__nav--prev {
  position: relative;
  width: 32px;
  height: 32px;
}
@media all and (min-width: 769px) {
  .l-yearly-pager .yearly-pager__nav--prev:hover.icon-arrow-left:before {
    left: 2px;
  }
}
.l-yearly-pager .yearly-pager__nav--next.icon-arrow-right:before,
.l-yearly-pager .yearly-pager__nav--prev.icon-arrow-left:before {
  position: absolute;
  top: 7px;
  left: 6px;
  color: #333;
  transition: left 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99);
}
@media all and (min-width: 769px) {
  .l-yearly-pager .yearly-pager__nav--next:hover.icon-arrow-right:before {
    left: 13px;
  }
}
.l-yearly-pager .yearly-pager__nav--next.icon-arrow-right:before {
  left: 9px;
}
.l-yearly-pager .yearly-pager__nav--num {
  padding: 11px 18px;
  background-color: #fff;
  color: #707070;
  border: 1px solid #e5e5e5;
  font-size: 16px;
  text-align: center;
  line-height: 1;
  transition: background-color 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99), color 0.5s cubic-bezier(0.23, 0.76, 0.37, 0.99);
}
.l-yearly-pager .yearly-pager__nav--num:hover {
  background-color: #eee;
  color: #707070;
  border: 1px solid #e5e5e5;
}
.l-yearly-pager .yearly-pager__nav--num.is-current {
  background-color: #fff;
  color: #707070;
  border-color: #707070;
  pointer-events: none;
}
.l-yearly-pager .yearly-pager__nav--space {
  width: 20px;
  height: 40px;
  background: url(../images/pager-space.png) no-repeat center bottom;
  background-size: 12px 3px;
}
.l-yearly-pager .yearly-pager-sp {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 15px 0;
  border-bottom: 1px solid #e0e0e0;
}
.l-yearly-pager .yearly-pager-sp__nav {
  position: relative;
  width: 30%;
  font-size: 12px;
  text-align: center;
  color: #333;
  font-weight: 700;
}
.l-yearly-pager .yearly-pager-sp__nav.is-disable,
.l-yearly-pager .yearly-pager-sp__nav.is-disable .icon-arrow-left:before,
.l-yearly-pager .yearly-pager-sp__nav.is-disable .icon-arrow-right:before {
  color: #b2b2b2;
}
.l-yearly-pager .yearly-pager-sp__nav--next,
.l-yearly-pager .yearly-pager-sp__nav--prev {
  padding: 5px 10px;
}
.l-yearly-pager .yearly-pager-sp__nav--next .icon-arrow-right:before,
.l-yearly-pager .yearly-pager-sp__nav--prev .icon-arrow-left:before {
  position: absolute;
  top: 50%;
  width: 12px;
  height: 12px;
  font-size: 12px;
  color: #141316;
  text-decoration: none;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.l-yearly-pager .yearly-pager-sp__nav--prev .icon-arrow-left:before {
  left: 5px;
}
.l-yearly-pager .yearly-pager-sp__nav--next .icon-arrow-right:before {
  right: 5px;
}
.l-yearly-pager .yearly-pager-sp__select {
  position: relative;
  width: 40%;
  border-left: 1px solid #d9d8d8;
  border-right: 1px solid #d9d8d8;
}
.l-yearly-pager .yearly-pager-sp__select__num {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  padding: 11px 0;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.075em;
  background: url(../images/select-pager-bg.png) no-repeat calc(50% + 37px) center;
  background-size: 12px 8px;
}

/*============================
m-pagetop
============================*/
.m-pagetop {
  appearance: none;
  resize: none;
  padding: 0;
  border: 0;
  outline: none;
  background: transparent;
  font-family: YakuHanJP, "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "游ゴシック体", "Yu Gothic", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  position: fixed;
  display: none;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  width: 60px;
  height: 60px;
  top: auto;
  bottom: 40px;
  left: auto;
  right: 20px;
  background: url(../img/global/footer/m-pagetop.svg) no-repeat left top;
  background-size: contain;
  z-index: 99999;
  cursor: pointer;
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
  backface-visibility: hidden;
}
.m-pagetop:hover {
  opacity: 0.7;
  transition: 0.3s;
}
@media screen and (max-width: 767px) {
  .m-pagetop {
    width: 35px;
    height: 35px;
  }
}

/*============================
m-button
============================*/
.m-button {
  width: 150px;
  height: 40px;
  font-size: 16px;
  color: #EE8A18 !important;
  line-height: 1em;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 20px;
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
  backface-visibility: hidden;
}
.m-button:hover {
  opacity: 0.7;
  transition: 0.3s;
}
.m-button-black {
  width: 200px;
  font-size: 15px;
  color: #ffffff !important;
  background-color: #2C2C2C;
}
@media screen and (max-width: 767px) {
  .m-button-black {
    font-size: 11px;
  }
}
.m-button-grey {
  width: 200px;
  font-size: 15px;
  color: #ffffff !important;
  background-color: #BABABA;
}
@media screen and (max-width: 767px) {
  .m-button-grey {
    font-size: 11px;
  }
}
.m-button-more {
  width: 510px;
  height: 100px;
  margin: 0 auto;
  position: relative;
  border: 1px solid #EE8A18;
  border-radius: initial;
  font-size: 18px;
}
@media screen and (max-width: 767px) {
  .m-button-more {
    width: 100%;
    height: 65px;
    font-size: 14px;
  }
}
.m-button-more::after {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  background: url(../img/global/link_external.svg) no-repeat left top;
  background-size: contain;
  margin-left: 10px;
}
@media screen and (max-width: 767px) {
  .m-button-more::after {
    width: 12px;
    height: 12px;
  }
}

/*============================
m-hide
============================*/
@media screen and (max-width: 767px) {
  .m-hide-sp {
    display: none;
  }
}
.m-hide-pc {
  display: none;
}
@media screen and (max-width: 767px) {
  .m-hide-pc {
    display: block;
  }
}

/*============================
m-tit
============================*/
.m-tit-white .m-tit__ja {
  color: #ffffff;
}
.m-tit-white .m-tit__en {
  color: #ffffff;
}
.m-tit-line {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .m-tit-line {
    font-size: 16px;
  }
}
.m-tit-line::before, .m-tit-line::after {
  content: "";
  display: block;
  width: 25px;
  height: 1px;
  background-color: #707070;
}
@media screen and (max-width: 767px) {
  .m-tit-line::before, .m-tit-line::after {
    width: 17px;
  }
}
.m-tit-line::before {
  margin-right: 15px;
}
@media screen and (max-width: 767px) {
  .m-tit-line::before {
    margin-right: 10px;
  }
}
.m-tit-line::after {
  margin-left: 15px;
}
@media screen and (max-width: 767px) {
  .m-tit-line::after {
    margin-left: 10px;
  }
}
.m-tit-under {
  font-size: 20px;
}
.m-tit-under::after {
  content: "";
  display: block;
  width: 25px;
  height: 1px;
  background-color: #707070;
  margin: 10px auto 0;
}
.m-tit-label {
  background-color: #F4F4F4;
  padding: 15px 20px;
  text-align: left;
}
@media screen and (max-width: 767px) {
  .m-tit-label {
    padding: 10px 20px;
  }
}
.m-tit__ja {
  display: block;
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .m-tit__ja {
    font-size: 20px;
  }
}
.m-tit__en {
  display: block;
  font-size: 12px;
  font-family: "Oswald", sans-serif;
  color: #EE8A18;
}

/*============================
m-noposts
============================*/
.m-noposts {
  width: 100%;
  padding: 0 20px;
  text-align: center;
}

/*============================
m-pagenavi
============================*/
.m-pagenavi {
  margin-top: 100px;
}
@media screen and (max-width: 767px) {
  .m-pagenavi {
    margin-top: 40px;
  }
}
.m-pagenavi a, .m-pagenavi span {
  font-size: 14px;
  display: inline-block;
  line-height: 1;
  color: #BABABA !important;
  padding: 5px 4px 10px;
  margin: 0 6px;
  transition: 0.3s;
}
@media screen and (max-width: 767px) {
  .m-pagenavi a, .m-pagenavi span {
    font-size: 13px;
  }
}
.m-pagenavi a:hover, .m-pagenavi span:hover {
  transition: 0.3s;
  color: #EE8A18 !important;
  border-bottom: 1px solid #EE8A18;
}
.m-pagenavi .current {
  color: #EE8A18 !important;
  border-bottom: 1px solid #EE8A18;
}

/*============================
m-desc
============================*/
.m-desc {
  text-align: left;
}
.m-desc-center {
  text-align: center;
}
.m-desc p {
  font-size: 16px;
  line-height: 1.87;
  letter-spacing: 0.12em;
}
@media screen and (max-width: 767px) {
  .m-desc p {
    font-size: 12px;
  }
}
@media screen and (max-width: 767px) {
  .m-desc p + p {
    margin-top: 20px;
  }
}
.m-desc-split p + p {
  margin-top: 30px;
}

/*============================
m-tit
============================*/
.m-subtit {
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .m-subtit {
    font-size: 16px;
  }
}
.m-subtit-bold {
  font-weight: bold;
}

/*============================
m-scroll
============================*/
.m-scroll {
  height: 100px;
}
.m-scroll__line {
  height: 100%;
}
.m-scroll__line::before {
  height: 100px;
  background-color: #ffffff;
  animation: scrollLine 1.4s linear infinite;
}
@keyframes scrollLine {
  0% {
    height: 100px;
  }
  100% {
    height: 0;
  }
}
@media screen and (max-width: 767px) {
  .m-scroll {
    height: 50px;
  }
  .m-scroll__line {
    height: 100%;
  }
  .m-scroll__line::before {
    height: 50px;
    background-color: #ffffff;
    animation: scrollLine 1.4s linear infinite;
  }
  @keyframes scrollLine {
    0% {
      height: 50px;
    }
    100% {
      height: 0;
    }
  }
}
.m-scroll__tit {
  font-size: 12px;
  color: #ffffff;
  font-family: "Oswald", sans-serif;
  padding-bottom: 10px;
}
.m-scroll__line {
  position: relative;
}
.m-scroll__line::before {
  content: "";
  width: 1px;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateZ(0);
}

/*============================
.m-select
============================*/
.m-select {
  width: 356px;
  height: 60px;
  margin: 0 auto;
  position: relative;
  border: 1px solid #E0E0E0;
}
@media screen and (max-width: 767px) {
  .m-select {
    width: 100%;
    height: 42px;
  }
}
.m-select::after {
  content: "";
  display: block;
  pointer-events: none;
  width: 8px;
  height: 8px;
  border-top: 1px solid #2C2C2C;
  border-right: 1px solid #2C2C2C;
  transform: rotate(135deg);
  position: absolute;
  top: 40%;
  left: auto;
  right: 20px;
}
@media screen and (max-width: 767px) {
  .m-select::after {
    width: 5px;
    height: 5px;
  }
}
.m-select select {
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  font-family: YakuHanJP, "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "游ゴシック体", "Yu Gothic", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 20px;
  font-size: 16px;
}
.m-select select::-ms-expand {
  display: none;
}
@media screen and (max-width: 767px) {
  .m-select select {
    font-size: 12px;
    padding: 0 15px;
  }
}

/*==========================
共通タブ用 => .js-tab
==========================*/
.js-tab__switchItem {
  cursor: pointer;
}
.js-tab__main {
  position: relative;
}
.js-tab__mainItem {
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.js-tab__mainItem.has-scroll {
  display: none;
  position: static;
}
.js-tab__mainItem.is-active {
  opacity: 1;
  visibility: visible;
  position: static;
  z-index: auto;
}
.js-tab__mainItem.is-active.has-scroll {
  display: block;
}

/*===================
タブの中のタブ => .js-tab_inr
===================*/
.js-tabInr__switchItem {
  cursor: pointer;
}
.js-tabInr__main {
  position: relative;
}
.js-tabInr__mainItem {
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.js-tabInr__mainItem.has-scroll {
  display: none;
  position: static;
}
.js-tabInr__mainItem.is-active {
  opacity: 1;
  visibility: visible;
  position: static;
  z-index: auto;
}
.js-tabInr__mainItem.is-active.has-scroll {
  display: block;
}

/*==========================
共通トグル用 => .js-toggle
==========================*/
.js-toggle__switch {
  position: relative;
  cursor: pointer;
}
.js-toggle__switch::before, .js-toggle__switch::after {
  content: "";
  width: 13px;
  height: 2px;
  background: #000000;
  display: block;
  position: absolute;
  top: 50%;
  left: auto;
  right: 20px;
}
.js-toggle__switch::before {
  transform: translateY(-50%) rotate(0);
}
.js-toggle__switch::after {
  transition: 0.3s;
  transform: translateY(-50%) rotate(90deg);
}
.js-toggle__switch.is-active {
  transition: 0.3s;
}
.js-toggle__switch.is-active::after {
  transform: translateY(-50%) rotate(0deg);
}
.js-toggle__next {
  display: none;
}

/*==================
js-fadeIn
==================*/
.js-fadeIn {
  opacity: 0;
  transition: 1s;
}
.js-fadeIn.is-show {
  opacity: 1;
}
.js-fadeIn-up {
  transform: translate(0, 50px);
}
.js-fadeIn-up.is-show {
  transform: translate(0, 0);
  opacity: 1;
}

/*==========================
共通スライダー用 => .js-slider
==========================*/
.js-slider__modulDots {
  position: relative;
}
.js-slider__modulArw {
  cursor: pointer;
}

/* slick-theme */
.slick-loading .slick-list {
  background: #fff url(../js/libs/slick/ajax-loader.gif) center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  font-weight: normal;
  font-style: normal;
  src: url("../js/libs/slick/fonts/slick.eot");
  src: url("../js/libs/slick/fonts/slick.eot?#iefix") format("embedded-opentype"), url("../js/libs/slick/fonts/slick.woff") format("woff"), url("../js/libs/slick/fonts/slick.ttf") format("truetype"), url("../js/libs/slick/fonts/slick.svg#slick") format("svg");
}
/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 50px;
  height: 50px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 999;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  opacity: 0.7;
}

.slick-prev {
  left: -25px;
  background-image: url("../js/libs/slick//img/prev_arrow.png");
}

.slick-next {
  right: -25px;
  background-image: url("../js/libs/slick//img/next_arrow.png");
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

/* slick */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/*==========================
トップ
.p-top
==========================*/
.p-top-common__head {
  background-color: #EE8A18;
  padding: 50px 0;
}
.p-top-common__headTit {
  position: relative;
  display: inline-block;
}
.p-top-common__headTitJa {
  font-size: 24px;
  color: #ffffff;
}
@media screen and (max-width: 767px) {
  .p-top-common__headTitJa {
    font-size: 20px;
  }
}
.p-top-common__headTitIcon {
  position: absolute;
  top: 10px;
  left: auto;
  right: -70px;
}
@media screen and (max-width: 767px) {
  .p-top-common__headTitIcon {
    width: 20.9vw;
    right: -40px;
  }
}
.p-top-common__headTitIcon img {
  max-width: 100%;
}
.p-top-common__headSub {
  margin-top: 60px;
}
.p-top-common__headSubTit {
  font-size: 28px;
  color: #ffffff;
}
@media screen and (max-width: 767px) {
  .p-top-common__headSubTit {
    font-size: 16px;
  }
}
.p-top-common__headSubTxt {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .p-top-common__headSubTxt {
    margin-top: 15px;
  }
}
.p-top-common__headSubTxt p {
  font-size: 17px;
  color: #ffffff;
  line-height: 1.76;
}
@media screen and (max-width: 767px) {
  .p-top-common__headSubTxt p {
    font-size: 12px;
    line-height: 1.75;
  }
}
.p-top-common__headSub .m-button {
  width: 200px;
  margin: 40px auto 0;
}
@media screen and (max-width: 767px) {
  .p-top-common__headSub .m-button {
    width: 33.3vw;
    height: 28px;
    font-size: 11px;
    margin-top: 20px;
  }
}
.p-top-common__number {
  padding: 80px 0 0;
}
@media screen and (max-width: 767px) {
  .p-top-common__number {
    padding: 20px 0 0;
  }
}
.p-top-common__numberList {
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin-top: 60px;
}
@media screen and (max-width: 767px) {
  .p-top-common__numberList {
    display: block;
    margin-top: 30px;
  }
}
.p-top-common__numberListItem {
  width: calc(100% / 3);
  border-right: 1px solid #E0E0E0;
}
@media screen and (max-width: 767px) {
  .p-top-common__numberListItem {
    width: auto;
    border: none;
    margin-top: 25px;
  }
}
.p-top-common__numberListItem:first-of-type {
  border-left: 1px solid #E0E0E0;
}
@media screen and (max-width: 767px) {
  .p-top-common__numberListItem:first-of-type {
    border: none;
    margin-top: 0;
  }
}
.p-top-common__numberListTit {
  font-size: 20px;
}
@media screen and (max-width: 767px) {
  .p-top-common__numberListTit {
    font-size: 16px;
  }
}
.p-top-common__numberListTit::after {
  content: "";
  display: block;
  width: 25px;
  height: 1px;
  background-color: #707070;
  margin: 10px auto 0;
}
.p-top-common__numberListIcon {
  margin-top: 20px;
}
.p-top-common__numberListDesc {
  margin-top: 20px;
}
.p-top-common__numberListDesc p {
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .p-top-common__numberListDesc p {
    font-size: 16px;
  }
}
.p-top-common__numberListDesc p span {
  font-size: 60px;
  font-family: "Oswald", sans-serif;
  padding-right: 10px;
}
@media screen and (max-width: 767px) {
  .p-top-common__numberListDesc p span {
    font-size: 35px;
  }
}
.p-top-common__numberListAst {
  font-size: 12px;
}
.p-top-common__number__ast {
  text-align: right;
  font-size: 12px;
  margin-top: 30px;
}
@media screen and (max-width: 767px) {
  .p-top .l-main__spacer {
    margin-top: 0;
  }
}
.p-top-information {
  margin-top: 100px;
}
@media screen and (max-width: 767px) {
  .p-top-information {
    margin-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .p-top-information .l-section__inner {
    padding: 0 0;
  }
}
.p-top-information__headPost {
  border: 10px solid #F4F4F4;
  padding: 15px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .p-top-information__headPost {
    display: block;
  }
}
.p-top-information__headPostSplit {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-top-information__headPostSplit:nth-of-type(1) {
  width: 200px;
}
.p-top-information__headPostSplit:nth-of-type(2) {
  width: calc(100% - 200px);
}
@media screen and (max-width: 767px) {
  .p-top-information__headPostSplit:nth-of-type(2) {
    width: 100%;
  }
}
.p-top-information__headPostIcon {
  margin-right: 15px;
}
@media screen and (max-width: 767px) {
  .p-top-information__headPostIcon {
    width: 19px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 767px) {
  .p-top-information__headPostIcon img {
    max-width: 100%;
  }
}
.p-top-information__headPostCate {
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  .p-top-information__headPostCate {
    font-size: 12px;
    padding-top: 5px;
  }
}
.p-top-information__headPostTit {
  font-size: 16px;
  line-height: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 767px) {
  .p-top-information__headPostTit {
    font-size: 12px;
    padding-right: 0;
    margin-top: 5px;
    padding-left: 35px;
  }
}
.p-top-information__main {
  margin-top: 40px;
}
.p-top-information__link {
  margin-top: 60px;
}
@media screen and (max-width: 767px) {
  .p-top-information__link {
    margin-top: 38px;
  }
}
.p-top-information__link .m-button.m-button-black {
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .p-top-information__link .m-button.m-button-black {
    width: 33.3%;
    height: 28px;
    font-size: 11px;
  }
}
.p-top-company {
  margin-top: 120px;
}
@media screen and (max-width: 767px) {
  .p-top-company {
    margin-top: 50px;
  }
}
.p-top-company .l-link__listItem {
  width: calc(100% / 3);
}
@media screen and (max-width: 767px) {
  .p-top-company .l-link__listItem {
    width: 100%;
  }
}
.p-top-business {
  margin-top: 120px;
}
@media screen and (max-width: 767px) {
  .p-top-business {
    margin-top: 50px;
  }
}
.p-top-business__link-top .l-link__listItem {
  width: 25%;
}
@media screen and (max-width: 767px) {
  .p-top-business__link-top .l-link__listItem {
    width: 100%;
  }
}
.p-top-business__link-top .l-link__listBkImg {
  position: relative;
  overflow: hidden;
}
.p-top-business__link-top .l-link__listBkImg::after {
  content: "";
  display: block;
  padding-top: 64.3274853801%;
}
.p-top-business__link-top .l-link__listBkImg img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: top;
  font-family: "object-fit:cover;";
}
@media screen and (max-width: 767px) {
  .p-top-business__link-top .l-link__listBkImg {
    position: relative;
    overflow: hidden;
  }
  .p-top-business__link-top .l-link__listBkImg::after {
    content: "";
    display: block;
    padding-top: 29.3333333333%;
  }
  .p-top-business__link-top .l-link__listBkImg img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: top;
    font-family: "object-fit:cover;";
  }
}
.p-top-business__link-bottom {
  margin-top: 120px;
}
@media screen and (max-width: 767px) {
  .p-top-business__link-bottom {
    margin-top: 50px;
  }
}
.p-top-business__link-bottom .l-link__listItem {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .p-top-business__link-bottom .l-link__listItem {
    width: 100%;
  }
}
.p-top-business__link-bottom .l-link__listBkImg {
  position: relative;
  overflow: hidden;
}
.p-top-business__link-bottom .l-link__listBkImg::after {
  content: "";
  display: block;
  padding-top: 32.2108345534%;
}
.p-top-business__link-bottom .l-link__listBkImg img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: top;
  font-family: "object-fit:cover;";
}
@media screen and (max-width: 767px) {
  .p-top-business__link-bottom .l-link__listBkImg {
    position: relative;
    overflow: hidden;
  }
  .p-top-business__link-bottom .l-link__listBkImg::after {
    content: "";
    display: block;
    padding-top: 29.3333333333%;
  }
  .p-top-business__link-bottom .l-link__listBkImg img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: top;
    font-family: "object-fit:cover;";
  }
}
.p-top-ir {
  margin-top: 120px;
}
@media screen and (max-width: 767px) {
  .p-top-ir {
    margin-top: 50px;
  }
}
@media screen and (max-width: 767px) {
  .p-top-ir .p-top-common__headTitIcon {
    width: 10vw;
    top: 30px;
    right: 0px;
  }
}
.p-top-ir .l-link__listItem {
  width: calc(100% / 3);
}
@media screen and (max-width: 767px) {
  .p-top-ir .l-link__listItem {
    width: 100%;
  }
}
.p-top-ir .l-link__listBkImg {
  position: relative;
  overflow: hidden;
}
.p-top-ir .l-link__listBkImg::after {
  content: "";
  display: block;
  padding-top: 48.2456140351%;
}
.p-top-ir .l-link__listBkImg img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: top;
  font-family: "object-fit:cover;";
}
@media screen and (max-width: 767px) {
  .p-top-ir .l-link__listBkImg {
    position: relative;
    overflow: hidden;
  }
  .p-top-ir .l-link__listBkImg::after {
    content: "";
    display: block;
    padding-top: 29.3333333333%;
  }
  .p-top-ir .l-link__listBkImg img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: top;
    font-family: "object-fit:cover;";
  }
}
.p-top-recruit {
  margin-top: 120px;
}
@media screen and (max-width: 767px) {
  .p-top-recruit {
    margin-top: 50px;
  }
}
@media screen and (max-width: 767px) {
  .p-top-recruit .p-top-common__headTitIcon {
    width: 16.6vw;
    top: 30px;
    right: -15px;
  }
}
.p-top-recruit__link {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
@media screen and (max-width: 767px) {
  .p-top-recruit__link {
    display: block;
  }
}
.p-top-recruit__linkSplit {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .p-top-recruit__linkSplit {
    width: 100%;
  }
}
.p-top-recruit__link .l-link__listItem {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .p-top-recruit__link .l-link__listItem {
    width: 100%;
  }
}
.p-top-recruit__link .l-link__listBkImg {
  position: relative;
  overflow: hidden;
}
.p-top-recruit__link .l-link__listBkImg::after {
  content: "";
  display: block;
  padding-top: 64.3274853801%;
}
.p-top-recruit__link .l-link__listBkImg img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: top;
  font-family: "object-fit:cover;";
}
@media screen and (max-width: 767px) {
  .p-top-recruit__link .l-link__listBkImg {
    position: relative;
    overflow: hidden;
  }
  .p-top-recruit__link .l-link__listBkImg::after {
    content: "";
    display: block;
    padding-top: 29.3333333333%;
  }
  .p-top-recruit__link .l-link__listBkImg img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: top;
    font-family: "object-fit:cover;";
  }
}
.p-top-recruit .l-footlink-recruit {
  margin-top: 0;
}
.p-top-recruit .l-footlink-recruit .l-link__listBkImg {
  position: relative;
  overflow: hidden;
}
.p-top-recruit .l-footlink-recruit .l-link__listBkImg::after {
  content: "";
  display: block;
  padding-top: 32.1637426901%;
}
.p-top-recruit .l-footlink-recruit .l-link__listBkImg img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: top;
  font-family: "object-fit:cover;";
}
@media screen and (max-width: 767px) {
  .p-top-recruit .l-footlink-recruit .l-link__listBkImg {
    position: relative;
    overflow: hidden;
  }
  .p-top-recruit .l-footlink-recruit .l-link__listBkImg::after {
    content: "";
    display: block;
    padding-top: 29.3333333333%;
  }
  .p-top-recruit .l-footlink-recruit .l-link__listBkImg img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: top;
    font-family: "object-fit:cover;";
  }
}
